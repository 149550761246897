import {
    GET_USERS,
} from "../constants/Users";

const initState = {
    loading: false,
    message: "",
    showMessage: false,
    redirect: "",
    userList: []
    // token: localStorage.getItem(GET_USERS),
};

const users = (state = initState, action) => {
    switch (action.type) {
        case GET_USERS:
            console.log('GETUSERS',action.payload);
            return {
                ...state,
                userList: action.payload.users,
                loading: false,
            };
        default:
            return state;
    }
};

export default users;
