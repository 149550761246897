import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const AppViews = () => {
    return (
        <Suspense fallback={<Loading cover="content" />}>
            <Switch>
                <Route exact path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
                <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
                <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
                <Route exact path={`${APP_PREFIX_PATH}/devices`} component={lazy(() => import(`./devices`))} />
                <Route exact path={`${APP_PREFIX_PATH}/devices/history`} component={lazy(() => import(`./devices/DeviceHistory`))} />
                <Route exact path={`${APP_PREFIX_PATH}/triggers`} component={lazy(() => import(`./triggers/index`))} />
                <Route exact path={`${APP_PREFIX_PATH}/profile`} component={lazy(() => import(`./profile/index`))} />
                <Route exact path={`${APP_PREFIX_PATH}/notification`} component={lazy(() => import(`./notification/index`))} />
                <Route path={`${APP_PREFIX_PATH}/map`} component={lazy(() => import(`./landmap`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
            </Switch>
        </Suspense>
    );
};

export default React.memo(AppViews);
