import { DashboardOutlined, UserOutlined, MobileOutlined, FieldTimeOutlined, NotificationOutlined, RadiusBottomleftOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
    {
        key: "Dashboard",
        title: "Dashboard",
        icon: NotificationOutlined,
        submenu: [
            {
                key: "Map",
                path: `${APP_PREFIX_PATH}/map`,
                title: "Field",
                icon: RadiusBottomleftOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: "Home",
                path: `${APP_PREFIX_PATH}/home`,
                title: "Dashboard",
                icon: DashboardOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: "Devices",
                path: `${APP_PREFIX_PATH}/devices`,
                title: "Report",
                icon: MobileOutlined,
                breadcrumb: false,
                submenu: [],
            },
            {
                key: "Notifications",
                path: `${APP_PREFIX_PATH}/notification`,
                title: "Notifications",
                icon: NotificationOutlined,
                breadcrumb: false,
                submenu: [],
            },
        ],
    },
    {
        key: "Settings",
        title: "Settings",
        icon: NotificationOutlined,
        // breadcrumb: false,
        submenu: [
            {
                key: "Settings",
                title: "Settings",
                icon: NotificationOutlined,
                submenu: [
                    {
                        key: "Users",
                        path: `${APP_PREFIX_PATH}/users`,
                        title: "Users",
                        icon: UserOutlined,
                        breadcrumb: false,
                        submenu: [],
                    },
                    {
                        key: "Triggers",
                        path: `${APP_PREFIX_PATH}/triggers`,
                        title: "Triggers",
                        icon: FieldTimeOutlined,
                        breadcrumb: false,
                        submenu: [],
                    },
                ],
            },
        ],
    },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
