import fetch from "auth/FetchInterceptor";

const userService = {};

userService.getUsers = function (params) {
    return fetch({
        url: "/organization/user",
        method: "get",
        headers: {
            "public-request": "true",
        },
        params,
    });
};

userService.getUserDevices = function (id) {
    return fetch({
        url: `/organization/user/${id}`,
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
};
userService.getFields = function () {
    return fetch({
        url: `/user/field`,
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
};
userService.getFieldTypes = function () {
    return fetch({
        url: `/user/fieldtypes`,
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
};
userService.addField = function (params) {
    return fetch({
        url: `/user/field`,
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: params,
    });
};

userService.deleteField = function (id) {
    return fetch({
        url: `/user/field/${id}`,
        method: "delete",
        headers: {
            "public-request": "true",
        },
    });
};

userService.linkDevices = function (params) {
    return fetch({
        url: `/organization/user/devices`,
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: params,
    });
};
userService.linkFields = function (params) {
    return fetch({
        url: `/organization/user/fields`,
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: params,
    });
};

userService.addUser = function (params) {
    return fetch({
        url: "/organization/user",
        method: "post",
        headers: {
            "public-request": "true",
        },
        data: params,
    });
};

userService.updateUser = function (params) {
    return fetch({
        url: "/user",
        method: "put",
        headers: {
            "public-request": "true",
        },
        data: params,
    });
};

userService.getInfo = function () {
    return fetch({
        url: "/user",
        method: "get",
        headers: {
            "public-request": "true",
        },
    });
};

userService.updateUserAvatar = function (params) {
    return fetch({
        url: "/user/avatar",
        method: "post",
        headers: {
            "public-request": "true",
            "Content-Type": "multipart/form-data",
        },
        data: params,
    });
};

export default userService;
