import {
    GET_USERS,
    SHOW_MESSAGE,
  } from '../constants/Users';
  
  export const setUsers = (users) => {
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    console.log('users',users);
    return {
      type: GET_USERS,
      payload: users
    }
  };
  
  export const showMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    message
  };
};
  