import antdZhCN from "antd/es/locale/zh_CN";
import frMsg from "../locales/zh_CN.json";

const ZhLang = {
  antd: antdZhCN,
  locale: "zh",
  messages: {
    ...frMsg,
  },
};
export default ZhLang;
