import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
    GET_USERS
} from '../constants/Users';
import {
    setUsers,
    showMessage
} from "../actions/Users";

import userService from 'services/UserService';


export function* getUsers() {
    yield takeEvery(GET_USERS, function* () {
          try {
              const user = yield call(userService.getUsers);
              console.log(user);
               yield put(setUsers(user.datas));

          } catch (error) {
              yield put(showMessage(error));
          }
      });
  }

  export default function* rootSaga() {
    yield all([
          fork(getUsers)
    ]);
  }