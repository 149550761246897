import React from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "@fontsource/montserrat";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

mapboxgl.accessToken = "pk.eyJ1IjoiYmF0emF5YTE2IiwiYSI6ImNsZTQ0eHF1djA1cGIzcm1tejV0eWczNzIifQ.Ksq_OZMytjnLSV4wkBQtZQ";
function App() {
    return (
        <div className="App">
            <Provider store={store}>
                <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                    <Router>
                        <Switch>
                            <Route path="/" component={Views} />
                        </Switch>
                    </Router>
                </ThemeSwitcherProvider>
            </Provider>
        </div>
    );
}

export default App;
