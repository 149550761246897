import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import IntlMessage from "components/util-components/IntlMessage";
import userService from "services/UserService";

const menuItem = [
    {
        title: "Profile",
        icon: SettingOutlined,
        path: "/app/profile",
    },
];

export const NavProfile = ({ signOut }) => {
    const profileImg = "/img/avatars/man.png";
    const [profile, setProfile] = useState();
    useEffect(() => {
        userService.getInfo().then((res) => {
            setProfile(res.datas);
        });
    }, []);

    const profileMenu = (
        <div className="nav-profile nav-dropdown">
            <div className="nav-profile-header">
                <div className="d-flex">
                    <Avatar size={45} src={profileImg} />
                    <div className="pl-3">
                        <h4 className="mb-0">
                            {profile?.firstname} {profile?.lastname}
                        </h4>
                    </div>
                </div>
            </div>
            <div className="nav-profile-body">
                <Menu>
                    {menuItem.map((el, i) => {
                        return (
                            <Menu.Item key={i}>
                                <a href={el.path}>
                                    <Icon type={el.icon} />
                                    <span className="font-weight-normal">
                                        <IntlMessage id={el.title} />
                                    </span>
                                </a>
                            </Menu.Item>
                        );
                    })}
                    <Menu.Item key={menuItem.length + 1} onClick={(e) => signOut()}>
                        <span>
                            <LogoutOutlined />
                            <span className="font-weight-normal">
                                <IntlMessage id="Sign Out" />
                            </span>
                        </span>
                    </Menu.Item>
                </Menu>
            </div>
        </div>
    );
    return (
        <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
            <Menu className="d-flex align-item-center" mode="horizontal">
                <Menu.Item key="profile">
                    <Avatar src={profileImg} />
                </Menu.Item>
            </Menu>
        </Dropdown>
    );
};

export default connect(null, { signOut })(NavProfile);
