import React from "react";
import { APP_NAME } from "configs/AppConfig";
import IntlMessage from "components/util-components/IntlMessage";

export default function Footer() {
    return (
        <footer className="footer">
            <span>
                <IntlMessage id="Copyright" /> &copy; {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> <IntlMessage id="All rights reserved" />
            </span>
        </footer>
    );
}
