import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";

export const APP_NAME = "SonorX";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const USER_PREFIX_PATH = "/users";
export const DEVICE_PREFIX_PATH = "/devices";
export const PROFILE_PREFIX_PATH = "/profile";

export const THEME_CONFIG = {
    navCollapsed: false,
    sideNavTheme: SIDE_NAV_LIGHT,
    locale: "zh",
    navType: NAV_TYPE_SIDE,
    topNavColor: "#3e82f7",
    headerNavColor: "",
    mobileNav: false,
    currentTheme: "light",
    direction: DIR_LTR,
};
